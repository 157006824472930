<template>
  <div>
    <div class="container">
      <div class="row ">
        <div class="col-6 align-content-center justify-content-center">
          <h1>Sopelka™</h1>
          <p>Сопоставлет два массива сообщений</p>
          <p>Извлекает токены, растущие наиболее существенно между массивами</p>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="p-4">
        <!-- <form> -->
        <div class="row">
          <div class="col-4">
            <!-- <div class="row"> -->
            <form enctype="multipart/form-data" novalidate>
              <div class="dropbox">
                <input type="file" name="file1" :disabled="isSaving(1)" @change="filesChange($event.target.files, 1)"
                  class="input-file" />
                <p v-if="isInitial(1)">Не загружен предшествующий период</p>
                <p v-if="isSaving(1)">Грузим предшествующий период...</p>
                <p v-if="isSuccess(1)">Предшествующий период получили!</p>
                <p v-if="isFailed(1)">
                  Что-то пошло не так...<br />
                  <span v-if="this.currentError[1] != ''">Покажите админу эту информацию:
                    {{ this.currentError[1] }}</span>
                </p>
              </div>
            </form>
            <!-- </div> -->
            <!-- <div class="row"> -->
            <form enctype="multipart/form-data" novalidate>
              <div class="dropbox">
                <input type="file" name="file2" :disabled="isSaving(2)" @change="filesChange($event.target.files, 2)"
                  class="input-file" />
                <p v-if="isInitial(2)">Не загружен текущий период</p>
                <p v-if="isSaving(2)">Грузим текущий период...</p>
                <p v-if="isSuccess(2)">Текущий период получили!</p>
                <p v-if="isFailed(2)">
                  Что-то пошло не так...<br />
                  <span v-if="this.currentError[2] != ''">Покажите админу эту информацию:
                    {{ this.currentError[2] }}</span>
                </p>
              </div>
            </form>
          </div>

          <div class="col-4">
            <file-proc-input id="text_name" inputKind="text" v-model="input_api.txt" label="Название поля с текстом"
              placeholder="например, Текст" />
            <file-proc-input id="eng_name" inputKind="text" v-model="input_api.eng"
              label="Название поля с вовлеченностью" placeholder="например, Вовлеченность" />

          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <a id="div-with-loading" class="btn btn-info vs-con-loading__container ml-3" @click="sendData"
            :style="[(filesLoaded ? calcInProgress : true) ? { 'pointer-events': 'none' } : {}]">
            Отправить данные на расчет</a>
          <a @click="downloadFile" class="btn btn-outline-primary ml-3">
            <download-icon class="fea icon-sm"></download-icon>Скачать последние
            результаты <br />
            <small>{{ this.lastEditTxt }} </small></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store/store";
import { MessageCircleIcon, BookIcon, DownloadIcon } from "vue-feather-icons";
import FileProcInput from "./fileProcInput.vue";
const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;




export default {
  components: {
    MessageCircleIcon,
    BookIcon,
    DownloadIcon,
    FileProcInput,
  },
  name: "Sopelka",
  data() {
    return {
      true_hostname: this.$hostname_ba_macro,
      input_api: {
        txt: "Текст",
        eng: "Вовлеченность",
        userId: store.state.user_id,
      },
      request_api: {
        userId: store.state.user_id,
      },
      currentStatus: { 1: STATUS_INITIAL, 2: STATUS_INITIAL },
      currentError: { 1: "", 2: "" },
      tableShow: false,
      showStatus: false,
      lastEdit: "",
      status: {
        rows_in: 0,
        rows_out: 0,
      },
      token: "",
      calcInProgress: false,
      excelResults: [],
      refreshTime: 1,
    };
  },

  computed: {
    filesLoaded: function () {
      return this.currentStatus[1] > 1 && this.currentStatus[2] > 1
    },
    lastEditTxt: function () {
      if (this.lastEdit == "") {
        return "Отчетов пока нет";
      } else {
        return `Последний отчет сделан: ${this.lastEdit}`;
      }
    },
    uploadUrl1: function () {
      return (
        "" +
        this.true_hostname +
        "/sopelka/uploadfile/1");
    },
    uploadUrl2: function () {
      return (
        "" +
        this.true_hostname +
        "/sopelka/uploadfile/2"
      );
    },
  },
  methods: {
    async pollLastSaved() {
      const url = `${this.true_hostname}/sopelka/checkLastEdited/${store.state.user_id}`;

      const ret = await this.axios
        .get(url)
        // get data
        .then((x) => x.data.path);
      // add url field
      console.log(ret);
      this.lastEdit = ret;
      return ret;
    },
    isInitial: function (i) {
      return this.currentStatus[i] === STATUS_INITIAL;
    },
    isSaving: function (i) {
      return this.currentStatus[i] === STATUS_SAVING;
    },
    isSuccess: function (i) {
      return this.currentStatus[i] === STATUS_SUCCESS;
    },
    isFailed: function (i) {
      return this.currentStatus[i] === STATUS_FAILED;
    },
    filesChange(fileList, fileId) {
      this.currentStatus[fileId] = STATUS_SAVING;
      // handle file changes
      var formData = new FormData();

      if (!fileList.length) return;
      // append the files to FormData
      formData.append("file", fileList[0]);
      // save it
      this.save(formData, fileId);
    },

    save(formData, fileId) {
      // upload data to the server
      this.upload(formData, fileId)
        .then((x) => {
          console.log(x);
          if (x == 200) {
            this.currentStatus[fileId] = STATUS_SUCCESS;
          } else {
            this.currentStatus[fileId] = STATUS_FAILED;
            this.currentError[fileId] = `Error ${x}`;
          }
        })
        .catch((err) => {
          this.currentError[fileId] = err.response;
          this.currentStatus[fileId] = STATUS_FAILED;
        });
    },

    upload(formData, fileId) {
      // const url = `${BASE_URL}/photos/upload`;
      const url = `${this.true_hostname}/sopelka/uploadfile/${fileId}/${store.state.user_id}`;
      const ret = this.axios
        .post(url, formData)
        // get data
        .then((x) => x.data);
      // add url field
      console.log(ret);
      return ret;
    },

    sendData() {
      this.$vs.loading({
        container: "#div-with-loading",
        scale: 0.5,
        opacity: 0.1,
      });
      this.calcInProgress = true;
      // console.log(this.input_api);
      this.axios({
        method: "post",
        url: `${this.true_hostname}/sopelka/process_data`,
        data: this.input_api,
      })
        .then((res) => {
          console.log("successful processing");
          console.log(res.data);
          this.pollLastSaved();
          this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        })
        .catch((err) => {
          console.log("unsuccessful processing");
          console.error(err);
          //loading.close();
          this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          //this.$router.push('prospect-pool')
        });
    },

    downloadFile() {
      this.input_api.userId = store.state.userId;

      this.axios({
        method: "post",
        url: `${this.true_hostname}/sopelka/fetch_results`,
        data: this.request_api,
        // data: {"userId": this.input_api.userId},
        responseType: "blob",
      }).then(function (response) {
        const blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        });
        const aEle = document.createElement("a"); // Create a label
        const href = window.URL.createObjectURL(blob); // Create downloaded link
        aEle.href = href;
        aEle.download = "report.xlsx"; // File name after download
        document.body.appendChild(aEle);
        aEle.click(); // Click to download
        document.body.removeChild(aEle); // Download complete remove element
        window.URL.revokeObjectURL(href); // Release blob object
      });
    },
  },
  beforeMount() {
    this.pollLastSaved();
  },
};
</script>


<style scoped></style>
